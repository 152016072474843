import React from "react"
import { navigate } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import {
  Flex,
  Text,
  Heading,
  Box,
  Button,
  UnorderedList,
  ListItem,
} from "@chakra-ui/react"
import HeroLeft from "../../components/hero-left"
import HeroRight from "../../components/hero-right"

const styles = {
  root: {
    width: "100%",
    height: "auto",
    px: "24px",
  },

  "heading-box": {
    margin: "0 auto",
    mb: "32px",
    width: { base: "100%", md: "100%", lg: "50%" },
    justifyContent: "center",
    alignItems: "center",
  },
  heading: {
    textAlign: "center",
    fontWeight: "bold",
    color: "rgba(0, 0, 0, 0.87);",
    fontSize: { base: "24px", md: "24px", lg: "40px" },
    lineHeight: { base: "24px", md: "24px", lg: "48px" },
    mt: { base: "24px", md: "24px", lg: "32px" },
  },

  hero: {
    "inner-box": {
      width: { base: "100%", md: "100%", lg: "1112px" },
      justifyContent: {
        base: "flex-start",
        md: "flex-start",
        lg: "space-between",
      },
      alignItems: { base: "center", md: "center", lg: "flex-start" },
      flexDirection: { base: "column", md: "column", lg: "row" },
    },
  },

  "title-box": {
    width: "100%",
    mb: { base: "24px", md: "24px", lg: "32px" },
    justifyContent: { base: "center", md: "center", lg: "flex-start" },
  },
  title: {
    fontSize: { base: "20px", md: "20px", lg: "24px" },
    lineHeight: { base: "28px", md: "28px", lg: "32px" },
    fontWeight: { base: "500", md: "500", lg: "700" },
    textAlign: { base: "center", md: "center", lg: "left" },
  },

  "content-box": {
    mt: { base: "47px", md: "47px", lg: "0px" },
    width: { base: "100%", md: "100%", lg: "429px" },
    flexDirection: "column",
    ml: { base: "0px", md: "0px", lg: "140px" },
  },

  "content-box-border": {
    flexDirection: "column",
    justifyContent: { base: "center", md: "center", lg: "flex-start" },
    border: { base: "1px solid #DCE0E5", md: "1px solid #DCE0E5", lg: "none" },
    borderRadius: { base: "8px", md: "8px", lg: "none" },
    padding: { base: "35px", md: "35px", lg: "0px" },
    mt: { base: "24px", md: "24px", lg: "0px" },
    width: { base: "100%", md: "100%", lg: "543px" },
  },

  text: {
    color: "#041424",
    fontSize: "16px",
    lineHeight: "24px",
    textAlign: { base: "center", md: "center", lg: "left" },
  },

  whitespace: {
    pb: "24px",
  },

  button: {
    mt: "24px",
    padding: "8px 16px",
    bg: "#129459",
    height: { base: "40px", md: "40px", lg: "56px" },
    width: "100%",
    borderRadius: "4px",
    color: "#F1F1F1",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: "bold",
    outline: "none",
    "&:hover,&:active,&:focus": {
      bg: "#129459",
      outline: "none",
      border: "none",
    },
  },
}
const navigteToLearnMoreAboutERAS = () =>
  navigate("/learn-more-about-eras-quality-and-outcomes-optimization")
const SectionDataTsunami = () => {
  return (
    <Box sx={styles.root}>
      <Flex sx={styles["heading-box"]}>
        <Heading sx={styles.heading}>Drowning in an ERAS Data Tsunami?</Heading>
      </Flex>
      <HeroLeft
        styles={styles.hero}
        Image={() => (
          <StaticImage
            src="../../images/eras/eras-data-tsunami-a.png"
            alt="Illustration of a doctor with a stack of books and a laptop beside them"
            placeholder="blurred"
          />
        )}
      >
        <Flex sx={styles["content-box"]}>
          <Text sx={styles.text}>
            Analyzing and tracking data is central to your success with ERAS,
            but also extremely difficult to manage. For most hospitals, data
            capture and extraction are 100% manual.
            <Text sx={styles.whitespace}></Text>
            With more than 70 composite metrics to be collected, summarizing and
            visualizing the data is cumbersome. And then you need to report the
            findings back to physicians and nurses, in the form of actionable
            intelligence, to drive and track meaningful changes.
            <Text sx={styles.whitespace}></Text>
            Avant-garde automates the entire process of extracting, summarizing,
            visualizing and distributing ERAS data points and findings. This
            automation greatly reduces the cost and overhead of your manual
            process and helps you improve the effectiveness of your ERAS
            program.
          </Text>
        </Flex>
      </HeroLeft>
      <HeroRight
        styles={styles.hero}
        Image={() => (
          <StaticImage
            src="../../images/eras/eras-data-tsunami-b.png"
            alt="Illustration of a person looking out of a telescope with gears and puzzle piece background"
            placeholder="blurred"
          />
        )}
      >
        <Flex sx={styles["content-box-border"]}>
          <Flex sx={styles["title-box"]}>
            <Heading sx={styles.title}>
              What are your greatest challenges to ERAS Compliance?
            </Heading>
          </Flex>

          <UnorderedList>
            <ListItem>Finding and extracting data from the EMR</ListItem>
            <ListItem>
              Manually reviewing extracted data and correcting errors
            </ListItem>
            <ListItem>
              Analyzing composite metrics to identify ERAS compliance gaps and
              opportunities for improvement
            </ListItem>
            <ListItem>
              Formatting the findings for effective use by physicians,
              anesthesiologists, and nurses
            </ListItem>
            <ListItem>
              Correlating ERAS compliance with reduced costs and improved
              outcomes
            </ListItem>
          </UnorderedList>
          <Text sx={styles.whitespace}></Text>
          <Text sx={styles.text}>
            The unique Avant-garde platform obviates these challenges to greatly
            improves the efficiency and effectiveness of your ERAS program.
          </Text>
        </Flex>
      </HeroRight>

      <Flex
        sx={{
          flexDirection: "row",
          justifyContent: "center",
          width: "201px",
          margin: "0 auto",
          pb: "32px",
        }}
      >
        <Button
          title="Click here to take a closer look at our healthcare data analytics software in action."
          sx={styles.button}
          onClick={navigteToLearnMoreAboutERAS}
        >
          Take a Closer Look
        </Button>
      </Flex>
    </Box>
  )
}

export default SectionDataTsunami

import React from "react"
import { navigate } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { Flex, Text, Heading, Box, Button } from "@chakra-ui/react"
import { ChevronDownIcon } from "@chakra-ui/icons"

const styles = {
  root: {
    height: { base: "100%", md: "100%", lg: "600px" },
    width: "100%",
    px: "24px",
    overflowX: "hidden",
    overflowY: "scroll",
    "-ms-overflow-style": "none",
    "scrollbar-width": "none",
    "::-webkit-scrollbar": {
      display: "none",
    },
  },
  "inner-box": {
    margin: "0 auto",
    mt: { base: "32px", md: "32px", lg: "80px" },
    width: "100%",
    height: { base: "100%", md: "100%", lg: "456px" },
    flexDirection: { base: "column-reverse", md: "column-reverse", lg: "row" },
    alignItems: { base: "center", md: "center", lg: "flex-start" },
    justifyContent: {
      base: "flex-start",
      md: "flex-start",
      lg: "space-around",
    },
  },
  "left-box": {
    maxWidth: { base: "100%", md: "100%", lg: "700px" },
    flexDirection: "column",
    justifyContent: "center",
    alignItems: { base: "center", md: "center", lg: "flex-start" },
  },
  heading: {
    mt: { base: "24px", md: "24px", lg: "0px" },
    mb: { base: "24px", md: "24px", lg: "32px" },
    fontWeight: "bold",
    fontSize: { base: "24px", md: "24px", lg: "40px" },
    lineHeight: { base: "32px", md: "32px", lg: "48px" },
    color: "rgba(0, 0, 0, 0.87)",
    textAlign: { base: "center", md: "center", lg: "left" },
  },

  text: {
    mt: { base: "16px", md: "16px", lg: "24px" },
    fontSize: "16px",
    lineHeight: "150%",
    letterSpacing: "0.0015em",
    color: "#041424",
    textAlign: { base: "center", md: "center", lg: "left" },
  },
  image: {
    height: { base: "auto", md: "auto", lg: "313.95px" },
    minWidth: { base: "100%", md: "100%", lg: "400px" },
    textAlign: { base: "center", md: "center", lg: "right" },
  },
  "icon-box": {
    display: { base: "flex", md: "flex", lg: "none" },
    my: "17px",
    width: "100%",
    justifyContent: "center",
  },
  icon: {
    display: { base: "flex", md: "flex", lg: "none" },
    color: "#9CACBC",
    fontSize: "42px",
  },

  whitespace: {
    pb: "24px",
  },
  button: {
    mt: "24px",
    padding: "8px 16px",
    bg: "#129459",
    height: { base: "40px", md: "40px", lg: "56px" },
    width: { base: "100%", md: "100%", lg: "232px" },
    borderRadius: "4px",
    color: "#F1F1F1",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: "bold",
    outline: "none",
    "&:hover,&:active,&:focus": {
      bg: "#129459",
      outline: "none",
      border: "none",
    },
  },
}

const navigteToLearnMoreAboutERAS = () => navigate("/learn-more-about-eras-quality-and-outcomes-optimization")

const SectionHero = () => (
  <Box sx={styles.root}>
    <Flex sx={styles["inner-box"]}>
      <Flex sx={styles["icon-box"]}>
        <ChevronDownIcon sx={styles.icon} />
      </Flex>
      <Flex sx={styles["left-box"]}>
        <Heading sx={styles.heading}>
          Achieve the Full Potential of ERAS
        </Heading>
        <Text sx={styles.text}>
          When consistently followed, ERAS (Enhanced Recovery After Surgery)
          protocols are widely demonstrated to yield clinical, operational and
          financial benefits.
          <Text sx={styles.whitespace}></Text>
          But the administrative overhead required to find and correct gaps in
          ERAS compliance is significant. Using manual processes, many hospitals
          are forced to establish teams of analysts.
          <Text sx={styles.whitespace}></Text>
          Avant-garde helps you achieve the full potential of ERAS by
          streamlining compliance and providing the analytics needed to quickly
          spot and close the gaps.
        </Text>
        <Button sx={styles.button} onClick={navigteToLearnMoreAboutERAS}>
          Take a Closer Look
        </Button>
      </Flex>
      <Box sx={styles.image}>
        <StaticImage
          src="../../images/eras/eras-hero.png"
          alt="Illustration of a patient in a hospital bed and doctor standing standing beside them. "
          placeholder="blurred"
        />
      </Box>
    </Flex>
  </Box>
)

export default SectionHero

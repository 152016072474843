import React from "react"
import { navigate } from "gatsby"
import { Box, Button, Flex, Heading, Text } from "@chakra-ui/react"
import Video from "../video"

const styles = {
  root: {
    height: "auto",
    width: "100%",
    px: "24px",
  },
  "heading-box": {
    margin: "0 auto",
    mb: "32px",
    width: { base: "100%", md: "100%", lg: "100%" },
    justifyContent: "center",
    alignItems: "center",
  },
  heading: {
    textAlign: "center",
    fontWeight: "bold",
    color: "rgba(0, 0, 0, 0.87);",
    fontSize: { base: "24px", md: "24px", lg: "40px" },
    lineHeight: { base: "32px", md: "32px", lg: "40px" },
    mt: { base: "24px", md: "24px", lg: "32px" },
  },

  hero: {
    height: "auto",
    width: { base: "100%", md: "100%", lg: "490.67px" },
    mb: { base: "24px", md: "24px", lg: "0px" },
    textAlign: { base: "center", md: "center", lg: "left" },
  },

  "inner-box": {
    flexDirection: { base: "column-reverse", md: "column-reverse", lg: "row" },
    justifyContent: {
      base: "flex-start",
      md: "flex-start",
      lg: "space-between",
    },
    alignItems: "center",
    width: { base: "100%", md: "100%", lg: "1112px" },
    margin: "0 auto",
  },

  "left-box": {
    flexDirection: "column",
    justifyContent: "center",
    my: { base: "24px", md: "24px", lg: "0px" },
    width: { base: "100%", md: "100%", lg: "439px" },
  },
  text: {
    color: "#041424",
    fontSize: "16px",
    lineHeight: "24px",
    textAlign: { base: "center", md: "center", lg: "left" },
  },

  whitespace: {
    pb: "24px",
  },

  button: {
    mt: "24px",
    padding: "8px 16px",
    bg: "#129459",
    height: { base: "40px", md: "40px", lg: "56px" },
    width: "100%",
    borderRadius: "4px",
    color: "#F1F1F1",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: "bold",
    outline: "none",
    "&:hover,&:active,&:focus": {
      bg: "#129459",
      outline: "none",
      border: "none",
    },
  },
}

const navigteToLearnMoreAboutERAS = () =>
  navigate("/learn-more-about-eras-quality-and-outcomes-optimization")
const SectionCaseStudy = () => {
  return (
    <Box sx={styles.root}>
      <Flex sx={styles["heading-box"]}>
        <Heading sx={styles.heading}>ERAS Streamlined: A Case Study</Heading>
      </Flex>
      <Flex sx={{ width: "100%" }}>
        <Flex sx={styles["inner-box"]}>
          <Flex sx={styles["left-box"]}>
            <Text sx={styles.text}>
              Brigham and Women’s Hospital is a healthcare innovator, exhibited
              by its certification as one of only four ERAS Centers of
              Excellence in the United States.
              <Text sx={styles.whitespace}></Text>
              Historically, ERAS required 3-4 Data Coordinators to conduct
              manual chart reviews, extract data, analyze raw data for accuracy,
              and create data visualization tools & presentations.
              <Text sx={styles.whitespace}></Text>
              Using the Avant-garde Health platform, BWH has streamlined their
              ERAS processes, reducing manual work by 50-75%, allowing resources
              to be reallocated to accelerating additional patient quality
              improvement initiatives. BWH Data Coordinators highlight their
              “easy access to credible data and the ability to quickly identify
              issues and trends.”
            </Text>
          </Flex>
          <Box sx={styles.hero}>
            <Video video="https://player.vimeo.com/video/783680470?h=7f784a6c95" />
          </Box>
        </Flex>
      </Flex>

      <Flex
        sx={{
          flexDirection: "row",
          justifyContent: "center",
          width: "147px",
          margin: "0 auto",
          pb: "32px",
        }}
      >
        <Button
          title="Click here to take a closer look at our healthcare data analytics software in action."
          sx={styles.button}
          onClick={navigteToLearnMoreAboutERAS}
        >
          Learn More
        </Button>
      </Flex>
    </Box>
  )
}
export default SectionCaseStudy

import React from "react"
import SectionDataTsunami from "../components/eras/section-data-tsunami"
import SectionHero from "../components/eras/section-hero"
import Layout from "../components/layout"
import SectionStreamlined from "../components/eras/section-streamlined"
import SectionCaseStudy from "../components/eras/section-case-study"
import SectionCloserLook from "../components/eras/section-closer-look"

class ERASIndex extends React.Component {
  render() {
    return (
      <Layout location={this.props.location} title="ERAS | Avant-Garde Health">
        <SectionHero />
        <SectionDataTsunami />
        <SectionStreamlined />
        <SectionCaseStudy />
        <SectionCloserLook />
      </Layout>
    )
  }
}

export default ERASIndex
